<template>
<div class="comp-stat row-center-start">
  <div class="cs-item" v-for="(item, index) in data" :key="index">
    <div class="csi-top row-center-between">
      <div class="csit-left">
        <span>{{ item.value }}</span>
        <span>人</span>
      </div>
      <div class="csit-right">
        <el-icon :color="item.color">
          <Histogram />
        </el-icon>
      </div>
    </div>
    <div class="csi-bottom">{{ item.title + "（" + item.eng  + "）"}}</div>
  </div>
</div>
</template>

<script>
// 顶部统计块 公共组件
import { Histogram } from '@element-plus/icons-vue';
import { reactive, toRefs } from "vue";
export default {
  props: {
    data: { type: Array }
  },
  components: {
    Histogram,
  },
  setup() {
    const state = reactive({})
    return {
      ...toRefs(state)
    }
  }
}
</script>

<style lang="scss" scoped>
.comp-stat {
  width: 100%;

  .cs-item {
    width: 22%;
    padding: 10px 10px;
    margin-right: 14px;
    border-radius: 10px;
    -webkit-box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
    box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);

    &:last-child {
      margin-right: 0;
    }

    .csi-top {
      padding: 4px 4px;

      .csit-left {
        span {
          &:first-child {
            font-size: 38px;
            margin-right: 4px;
          }
        }
      }

      .csit-right {
        font-size: 40px;
      }
    }

    .csi-bottom {
      font-size: 14px;
    }
  }
}
</style>
