const state = {
  systemTitle: "药研有度运营管理平台",
  menuData: [
    {
      id: "1", title: "基础信息管理", show: true, icon: "iconfont icon-wenbenwenjian", children: [
        { id: "1-0", title: "申办方管理", icon: "", path: "/sponsorMa", children: [] },
        { id: "1-1", title: "研究中心管理", icon: "", path: "/researchMa", children: [] },
        { id: "1-2", title: "项目管理", icon: "", path: "/projectMa", children: [] },
        { id: "1-3", title: "费用类型管理", icon: "", path: "/expenseMa", children: [] },
      ]
    },
    {
      id: "2", title: "用户角色管理", show: true, icon: "iconfont icon-dingdanguanli", children: [
        { id: "2-0", title: "申办方管理员", icon: "", path: "/sponsorCharacter", children: [] },
        { id: "2-1", title: "研究者", icon: "", path: "/researcherCharacter", children: [] },
        { id: "2-2", title: "CRC", icon: "", path: "/crcCharacter", children: [] },
        { id: "2-3", title: "CRA", icon: "", path: "/craCharacter", children: [] },
      ]
    },
    {
      id: "3", title: "项目运营管理", show: true, icon: "iconfont icon-caiwuguanli", children: [
        { id: "3-0", title: "费用管理", icon: "", path: "/expense", children: [] },
        { id: "3-1", title: "发放记录", icon: "", path: "/releaseRecord", children: [] },
        { id: "3-2", title: "受试者管理", icon: "", path: "/subjectMa", children: [] },
        // { id: "3-3", title: "费用申请管理", icon: "", path: "/costRequest", children: [] },
      ]
    },
    {
      id: "4", title: "审计管理", show: true, icon: "iconfont icon-caiwuguanli", children: [
        { id: "4-0", title: "审计日志", icon: "", path: "/auditLog", children: [] },
      ]
    }
    
  ]
}

export default {
  state
};