import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: "/login",
    name: "Login",
    component: () => import(/* webpackChunkName: "login" */ 'views/login/index.vue'),
    meta: { title: "登录" }
  },
  {
    path: "/",
    redirect: "/sponsorMa"
  },
  {
    path: "/expense",
    name: "Expense",
    component: () => import(/* webpackChunkName: "expense" */ 'views/cost/expense.vue'),
    meta: { title: "费用管理", father: "cost" }
  },{
    path: "/releaseRecord",
    name: "ReleaseRecord",
    component: () => import(/* webpackChunkName: "expense" */ 'views/cost/releaseRecord.vue'),
    meta: { title: "发放记录", father: "cost" }
  },
  {
    path: "/expenseSummary",
    name: "ExpenseSummary",
    component: () => import(/* webpackChunkName: "expenseSummary" */ 'views/cost/expenseSummary.vue'),
    meta: { title: "费用汇总", father: "cost" }
  },
  {
    path: "/subjectMa",
    name: "SubjectMa",
    component: () => import(/* webpackChunkName: "subjectMa" */ 'views/cost/subjectMa.vue'),
    meta: { title: "受试者管理", father: "cost" }
  },
  // {
  //   path: "/costRequest",
  //   name: "CostRequest",
  //   component: () => import(/* webpackChunkName: "costRequest" */ 'views/cost/costRequest.vue'),
  //   meta: { title: "费用申请管理", father: "cost" }
  // },
  {
    path: "/sponsorMa",
    name: "SponsorMa",
    component: () => import(/* webpackChunkName: "sponsorMa" */ 'views/base/sponsor/index.vue'),
    meta: { title: "申办方管理", father: "base" }
  },
  {
    path: "/researchMa",
    name: "ResearchMa",
    component: () => import(/* webpackChunkName: "researchMa" */ 'views/base/research/index.vue'),
    meta: { title: "研究中心管理", father: "base" }
  },
  {
    path: "/projectMa",
    name: "ProjectMa",
    component: () => import(/* webpackChunkName: "projectMa" */ 'views/base/project/index.vue'),
    meta: { title: "项目管理", father: "base" }
  },
  {
    path: "/expenseMa",
    name: "ExpenseMa",
    component: () => import(/* webpackChunkName: "expenseMa" */ 'views/base/expense/index.vue'),
    meta: { title: "费用类型管理", father: "base" }
  },
  {
    path: "/crcCharacter",
    name: "CrcCharacter",
    component: () => import(/* webpackChunkName: "crcCharacter" */ 'views/character/crc/index.vue'),
    meta: { title: "CRC", father: "character" }
  },
  {
    path: "/craCharacter",
    name: "CraCharacter",
    component: () => import(/* webpackChunkName: "crcCharacter" */ 'views/character/cra/index.vue'),
    meta: { title: "CRA", father: "character" }
  },
  {
    path: "/sponsorCharacter",
    name: "SponsorCharacter",
    component: () => import(/* webpackChunkName: "sponsorCharacter" */ 'views/character/sponsor/index.vue'),
    meta: { title: "申办方管理员", father: "character" }
  },
  {
    path: "/researcherCharacter",
    name: "ResearcherCharacter",
    component: () => import(/* webpackChunkName: "researcherCharacter" */ 'views/character/researcher/index.vue'),
    meta: { title: "研究者", father: "character" }
  },
  {
    path: "/auditLog",
    name: "AuditLog",
    component: () => import(/* webpackChunkName: "auditLog" */ 'views/audit/log.vue'),
    meta: { title: "审计日志", father: "audit" }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// 路由导航首页
router.beforeEach((to, from, next) => {
  // 跳过开发环境
  if (process.env.NODE_ENV == "development") {
    next();
  } else {
    let operateInfo = JSON.parse(sessionStorage.getItem("operateInfo"));
    // 如果当前不是登录页
    if (to.path != "/login") {
      // 没有用户信息，跳转至登录页
      if (!operateInfo) {
        next("/login");
      } else {
        // 有 用户信息  正常跳转页面
        next();
      }
    } else {
      // 是登录页 继续
      next();
    }
  }
})

window.router = router;

export default router
