<template>
<div class="login-login" v-loading="loading">
  <div class="il-top column-center-center">欢迎登录药研有度运营管理平台</div>
  <div class="il-middle row-center-around">
    <div class="ilm-img"></div>
    <div class="ilm-form">
      <div class="ilmf-title">LOGIN</div>
      <div class="ilmf-contain">
        <div class="ilmfc-item">
          <span>用户名</span>
          <el-input v-model="username" placeholder="用户名"></el-input>
        </div>
        <div class="ilmfc-item">
          <span>登录密码</span>
          <el-input v-model="password" type="password" show-password placeholder="登录密码"></el-input>
        </div>
        <!-- 等保 -->
        <!-- <div class="ilmfc-item">
          <el-input v-model="yzm" placeholder="请输入验证码" class="input-with-select">
            <template #append>
              <el-button :disabled="yzmDisabled||(!formTest)" v-preventReClick @click="handleSendYzm">
                <template v-if="yzmDisabled">{{ yzm_Text }} 秒后重试</template>
                <template v-else>{{ yzm_Text }}</template>
              </el-button>
            </template>
          </el-input>
        </div> -->
        <el-button class="ilmfc-btn" :disabled="isDisabled" @click="goLogin">登录</el-button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
// 登录页
import { useRouter } from "vue-router";
import { ElMessage } from "element-plus";
import { sha256 } from 'js-sha256'
import { computed, reactive, toRefs, onMounted } from "vue";
import { getLogin, sendRoleVerificationCode } from "@/api/apis.js";
export default {
  setup() {
    const router = useRouter();
    const state = reactive({
      username: "",
      password: "",
      yzm: "",
      formTest: computed(() => {
        if ((state.username || "") == "" || (state.password || "") == "") {
          return false;
        } else {
          return true;
        }
      }),
      isDisabled: computed(() => {
        // 等保
        // if (state.username != "" && state.password != "" && state.yzm != "") {
        if (state.username != "" && state.password != "") {
          return false;
        } else {
          return true;
        }
      }),
      loading: false,
      yzm_Text: "发送验证码",
      timer: null,
      yzmDisabled: false
    });

    onMounted(() => {
      if (process.env.NODE_ENV == "development") {
        // lv.ting  lv.ting123
        // let data = { t_admin_id: 1, txt_admin_name: "Ann", password: "cxc123", txt_token: "0677e8af-8db5-405d-8c93-6f787fefaed6", is_first_login: null, is_change: null }
        let data = { t_admin_id: 1, txt_admin_name: "Ann", password: "cxc123", txt_token: "3de139ca-7cb0-48bb-9a63-86b5b27354fd", is_first_login: null, is_change: null }
        sessionStorage.setItem("operateInfo", JSON.stringify(data))
      }
    })

    // 登录
    const goLogin = () => {
      state.loading = true;
      // 等保
      // txt_code: state.yzm
      getLogin({
        txt_login_name: state.username,
        txt_login_psw: sha256(state.password),
      }).then((response) => {
        if (response.code == 200) {
          // 更新缓存，跳转至首页
          response.data.txt_login_name = state.username;
          response.data.password = state.password;
          // 更新缓存，用户信息
          sessionStorage.setItem("operateInfo", JSON.stringify(response.data));
          state.loading = false;
          router.replace({ path: "/" });
        } else {
          state.loading = false;
          ElMessage.error(response.msg);
        }
      }).catch((err) => {
        console.error(err);
        state.loading = false;
      });
    }

    // 发送验证码
    const handleSendYzm = () => {
      state.yzmDisabled = true;
      sendRoleVerificationCode({
        txt_login_name: state.username,
        txt_login_psw: sha256(state.password),
        txt_role: "ADMIN"
      }).then(response => {
        if (response.code == 200) {
          ElMessage.success(response.data.messages);
          const TIME_COUNT = 60;
          if (!state.timer) {
            state.yzm_Text = TIME_COUNT;
            state.timer = setInterval(() => {
              if (state.yzm_Text > 0 && state.yzm_Text <= TIME_COUNT) {
                state.yzm_Text--;
              } else {
                clearInterval(state.timer);
                state.yzmDisabled = false;
                state.yzm_Text = "发送验证码";
              }
            }, 1000)
          }
        } else {
          clearInterval(state.timer);
          state.yzmDisabled = false;
          state.yzm_Text = "发送验证码";
          ElMessage.error(response.msg);
        }
      })
    };

    return {
      ...toRefs(state),
      goLogin,
      handleSendYzm
    }
  }
}
</script>

<style lang="scss" scoped>
.login-login {
  height: 100%;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;

  .il-top {
    color: #1f225f;
    margin-top: 4%;
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 14px;
  }

  .il-middle {
    margin-top: 60px;
    width: 80%;
    height: 60%;

    .ilm-img {
      height: 100%;
      width: 50%;
      background-image: url("../../assets/imgs/undraw.svg");
      background-size: 100% 100%;
    }

    .ilm-form {
      width: 30%;
      color: #333333;
      padding: 20px 20px;
      border-radius: 20px;
      box-shadow: 0 0 10px 2px rgba($color: #b1b0b0, $alpha: 1.0);

      .ilmf-title {
        font-weight: bold;
        font-size: 24px;
        text-align: center;
        margin-bottom: 20px;
      }

      .ilmf-contain {
        height: calc(100% - 50px);

        .ilmfc-item {
          color: #9f9e9e;
          margin-bottom: 20px;

          .ilmfci-hint {
            color: rgb(235, 21, 21);
            font-size: 12px;
            margin-left: 4px;
          }

          .el-input {
            margin-top: 10px;

            /deep/ .el-input__wrapper {
              border-radius: 20px;
              box-shadow: inset 0 0 2px 1px rgba($color: #b1b0b0, $alpha: 1.0);
            }
          }

          /deep/ .el-input-group__append {
            margin-left: 8px;
          }
        }

        .ilmfc-btn {
          width: 100%;
          border-radius: 20px;
          background-color: #8a88c3;
          color: #ffffff;
          font-weight: bold;
          margin-top: 24px;
        }
      }
    }
  }
}
</style>
