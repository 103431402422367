
const getters = {
  menuData: state => {
    if (process.env.NODE_ENV == "development") {
      return state.settings.menuData;
    } else {
      let operateInfo = JSON.parse(sessionStorage.getItem("operateInfo")),
        result;
      if (operateInfo.t_admin_id == 6) {
        result = state.settings.menuData.filter(t => t.id == "4");
      } else {
        result = state.settings.menuData.filter(t => t.id != "4");
      }
      return result;
    }
  },
  operateInfo: () => {
    let operateInfo;
    if (process.env.NODE_ENV == "development") {
      operateInfo = {
        t_admin_id: 1,
        txt_admin_name: "Ann",
        password: "cxc123",
        txt_token: "0677e8af-8db5-405d-8c93-6f787fefaed6",
        is_first_login: 1, //null 首次登录 需要提示修改密码(强制修改) 1不需要修改
        is_change: null //用于接近90天修改密码的状态 null 不需要修改 1需要提示修改密码(强制修改)
      }
    } else {
      operateInfo = JSON.parse(sessionStorage.getItem("operateInfo"));
    }
    return operateInfo;
  },
  // 生成随机字符串
  UUID() { return 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx'.replace(/[x]/g, function () { return (Math.random() * 16 | 0).toString(16); }); },
};

export default getters;