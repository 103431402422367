<template>
<div class="layout-sidebar">
  <el-menu :default-active="activeIndex" class="el-menu-vertical-demo" :unique-opened="true" @select="handleSelect">
    <sidebar-item v-for="(item, index) in menuData" :key="index" :item="item" />
  </el-menu>
</div>
</template>

<script>
// 全局 - 左侧菜单
import { computed, reactive, toRefs, watch } from 'vue';
import { useStore } from "vuex";
import SidebarItem from "../SidebarItem/index.vue";
import { useRouter } from 'vue-router';
export default {
  components: {
    SidebarItem,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const state = reactive({
      menuData: computed(() => store.getters.menuData),
      activeIndex: "0"
    });

    // 监听路由
    watch(() => router.currentRoute.value.path, (newValue) => {
      // 权限
      let index = "0",
        targetIndex = -1;

      // 其他环境 隔离出来
      // 当前非首页
      if (newValue != "/") {
        // 循环菜单集合，展开对应菜单栏
        for (let i = 0; i < state.menuData.length; i++) {
          index = state.menuData[i].children.findIndex(t => t.path == newValue);
          if (index != -1) {
            targetIndex = i;
            break;
          }
        }
        // 访问没有的页面 直接跳转首页
        if (targetIndex == -1) {
          if (state.menuData[0].children.length > 0) {
            router.replace({ path: state.menuData[0].children[0].path });
            state.activeIndex = state.menuData[0].children[0].id;
          }
        } else {
          // active菜单状态赋值
          state.activeIndex = state.menuData[targetIndex].children[index].id;
        }
      }
    }, { immediate: true })

    const handleSelect = (key) => {
      console.log(key)
    }
    return {
      ...toRefs(state),
      handleSelect
    };
  }
}
</script>

<style lang="scss" scoped>
.layout-sidebar {
  height: calc(100% - 90px);

  .el-menu {
    border: none;
    border-top: 1px solid #fbfbff;
    color: #8a88c3;
    background-color: #ffffff;

    .el-menu-item {
      color: #bfcbd9;

      &:hover {
        background-color: rgba(0, 0, 0, 0.06) !important;
      }

      &.is-active {
        color: rgb(64, 158, 255);
      }
    }

    /deep/ {
      a {
        text-decoration: none;
      }

      .el-menu-item {
        padding: 0;
        width: 100%;

        a {
          width: calc(100% - 45px + 20px);
          color: #8a88c3;
        }

        &.is-active {
          a {
            color: #333333;
          }

        }
      }

      .el-sub-menu {
        &.is-active {
          .el-sub-menu__title {
            font-weight: bold;
            color: #8a88c3 !important;
          }
        }

        .el-sub-menu__title {
          font-weight: bold;
          font-size: 16px;
          color: #8a88c3 !important;

          &:hover {
            background-color: rgba(0, 0, 0, 0.06) !important;
          }
        }

        .el-menu--inline {
          background-color: #ffffff;

          .el-menu-item {
            color: #4f5b64 !important;

            &:hover {
              background-color: rgba(239, 236, 255, 0.5);
            }

            &.is-active {
              font-weight: bold;
              color: #333333 !important;
              background-color: #efecff;
            }
          }
        }
      }
    }
  }
}
</style>
