import axios from "axios";


axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";

// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_COMMON_API,
  timeout: 60000
});

// request 拦截器
service.interceptors.request.use(config => {
  // 所有请求中携带token
  let operateInfo = sessionStorage.getItem("operateInfo");
  if (operateInfo) {
    operateInfo = JSON.parse(operateInfo);
    config.headers.post['authtoken'] = operateInfo.txt_token;
    config.headers.get['authtoken'] = operateInfo.txt_token;
    return config;
  } else {
    if (config.url == "/RoleVerificationCode") {
      return config;
    } else {
      sessionStorage.clear();
      location.replace("/login");
      return false;
    }
  }
}, err => {
  console.error(err);
  Promise.reject(err);
});

// 响应拦截器
service.interceptors.response.use(res => {
  if (res.data.code == 1007) {
    sessionStorage.clear();
    location.replace("/login");
  } else {
    return res.data;
  }
}, err => {
  console.error(err);
  Promise.reject(err);
});

export default service;